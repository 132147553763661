<template lang="html">
  <div class="pillar-overview-tile">
    <div class="title">
      <IxRes>dlgCert.headers.{{ pillar }}_{{ route }}</IxRes>
    </div>

    <hr>

    <div class="tile-body">
      <div class="status-messages">
        <slot>
          <PillarOverviewStatusLine :warning="!routeDisabled && !finished">
            <IxRes>dlgCert.pillarOverview.status.{{ routeDisabled ? 'currentlyInDevelopment' : finished ? 'done' : 'noData' }}</IxRes>
          </PillarOverviewStatusLine>
        </slot>
        <slot name="additional-info" />
      </div>

      <IxButton
        v-if="$listeners.finish !== undefined"
        class="button"
        :disabled="finished"
        @click="$emit('finish')"
      >
        <IxRes>dlgCert.pillarOverview.buttons.markAsFinished</IxRes>
      </IxButton>
      <IxButton
        colored class="button"
        :disabled="routeDisabled"
        @click="$router.push({name: route})"
      >
        <slot name="button">
          <IxRes>dlgCert.pillarOverview.buttons.editDetails</IxRes>
        </slot>
      </IxButton>
    </div>
  </div>
</template>

<script>
import IxButton from '@components/IxButton'

import PillarOverviewStatusLine from './PillarOverviewStatusLine'

export default {
  components: {
    IxButton,
    PillarOverviewStatusLine
  },
  props: {
    showFinishButton: Boolean,
    routeDisabled: Boolean,
    finished: Boolean,
    pillar: String,
    route: String
  }
}
</script>

<style lang="scss" scoped>
.pillar-overview-tile {
  display: flex;
  flex-direction: column;
  padding: 1em;

  box-shadow: rgba(#000000, 0.4) 1px 2px 4px 0px;
  border-radius: 5px;
}

hr {
  margin: 1em -1em;
}

.title {
  text-transform: uppercase;
}

.status-messages {
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
}

.tile-body {
  display: flex;
  flex-direction: column;
  min-height: 7em;
}

.button {
  margin-top: 0.5em;
}
</style>
