<template lang="html">
  <svg viewBox="-50 -50 100 100" class="progress-circle">
    <circle
      class="background"
      cx="0" cy="0"
      :r="radius"
    />
    <circle
      class="foreground"
      :stroke-dasharray="fullCircle"
      :stroke-dashoffset="fullCircle * (1 - value)"
      cx="0" cy="0"
      :r="radius"
    />
  </svg>
</template>

<script>
export default {
  props: {
    value: Number
  },
  computed: {
    radius () {
      return 38
    },
    fullCircle () {
      return 2 * Math.PI * this.radius
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-circle {
  width: 1em;
  height: 1em;
  transform: rotate(-90deg);

  circle {
    stroke-width: 24;
    fill: none;

    &.background {
      stroke: #cbcbcb;
    }
    &.foreground {
      stroke: #009a44;
    }
  }

  margin-bottom: -0.15em;
  margin-right: 0.15em;
}
</style>
