<template lang="html">
  <PillarOverviewGrid v-if="data" :year="year">
    <PillarOverviewTile
      pillar="social"
      route="employees"
      :finished="data.employeeState.state == 'finished'" @finish="onFinish('employees')"
    >
      <PillarOverviewStatusLine>
        <IxRes :context="{n: data.employeeState.count}">dlgCert.Status.Employees.NFinished</IxRes>
      </PillarOverviewStatusLine>
      <PillarOverviewStatusLine>
        <IxRes v-if="data.employeeState.state === 'finished'">dlgCert.Status.Employees.CompletedSections</IxRes>
        <IxRes v-else-if="data.finished.employees">dlgCert.Status.Employees.ManualComplete</IxRes>
        <IxRes v-else>dlgCert.Status.Employees.Incomplete</IxRes>
      </PillarOverviewStatusLine>
    </PillarOverviewTile>

    <PillarOverviewTile
      pillar="social"
      route="salary"
    >
      <CompletedStatusLine :status="data.salaryState" />
    </PillarOverviewTile>

    <PillarOverviewTile
      pillar="social"
      route="training"
    >
      <CompletedStatusLine :status="data.trainingState.dataState" />
      <TrainingStatusLine :status="data.trainingState.certificateState" />
    </PillarOverviewTile>

    <PillarOverviewTile
      pillar="social"
      route="employeeMatters"
      :finished="data.employeeMattersState== 'finished'" @finish="onFinish('employeeMatters')"
    >
      <PillarOverviewStatusLine>
        <IxRes :context="{n: data.employeeMattersState}">dlgCert.Status.employeeMatters.NFinished</IxRes>
      </PillarOverviewStatusLine>
      <PillarOverviewStatusLine>
        <IxRes v-if="data.finished.employeeMatters">dlgCert.Status.employeeMatters.complete</IxRes>
      </PillarOverviewStatusLine>
    </PillarOverviewTile>

    <PillarOverviewTile
      pillar="social"
      route="safety"
    >
      <PillarOverviewStatusLine>
        <ProgressCircle :value="data.safetyState / 17" />
        <IxRes :context="{n: data.safetyState}">dlgCert.Status.safety.NFinished</IxRes>
      </PillarOverviewStatusLine>
    </PillarOverviewTile>

    <PillarOverviewTile
      pillar="social"
      route="engagement"
    >
      <PillarOverviewStatusLine>
        <IxRes :context="{n: data.engagementState}">dlgCert.Status.engagement.Finished</IxRes>
      </PillarOverviewStatusLine>
    </PillarOverviewTile>
  </PillarOverviewGrid>
</template>

<script>
import PillarOverviewGrid from './widgets/PillarOverviewGrid'
import PillarOverviewTile from './widgets/PillarOverviewTile'
import PillarOverviewStatusLine from './widgets/PillarOverviewStatusLine'
import CompletedStatusLine from './widgets/CompletedStatusLine'
import TrainingStatusLine from './widgets/TrainingStatusLine'
import ProgressCircle from './widgets/ProgressCircle'

import ApiMixin from '@dlg-cert/mixins/ApiMixin'

export default {
  components: {
    PillarOverviewGrid,
    PillarOverviewTile,
    PillarOverviewStatusLine,
    CompletedStatusLine,
    TrainingStatusLine,
    ProgressCircle
  },
  mixins: [
    ApiMixin
  ],
  props: {
    year: Number
  },
  computed: {
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/state/social/${this.$route.params.year}`
    },
    manualCompleteSections () {
      return {
        employees: false,
        employeeMatters: false
      }
    }
  },
  methods: {
    onFinish (route) {
      const url = `${route}`
      this.data.finished[route] = !this.data.finished[route]
      if (this.data.finished[route]) {
        this.api.post(url)
      } else {
        this.api.delete(url)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
