<template lang="html">
  <div class="overview-status-line" :class="{warning}">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    warning: Boolean
  }
}
</script>

<style lang="scss" scoped>
.overview-status-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.warning {
  color: red;
}
</style>
