<template lang="html">
  <div class="dashboard-status-line">
    <FontAwesomeIcon :icon="icon" class="icon" :class="{warning}" />
    <slot />
  </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faCheckCircle, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    warning: Boolean
  },
  computed: {
    icon () {
      return this.warning ? faExclamationTriangle : faCheckCircle
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-status-line {
  display: flex;
  align-items: center;
  margin: 0 2em;
}

.icon {
  margin-right: 0.5em;

  color: green;

  &.warning {
    color: red;
  }
}
</style>
